body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

form {
  background-color: springgreen;
}

input[type="text"] {
  color: darkblue;
  background-color: powderblue;
}

input[type="submit"] {
  color: black;
  background-color: gainsboro;
  font-weight: bold;
}

label {
  font-weight: bold;
}
